import React, {ChangeEvent, useRef, useState} from 'react';
import {Button, Container, ListGroup, Spinner} from "react-bootstrap";
import axiosInstance from "../../api";
import {toast} from "react-toastify";
import { useQuery } from '@tanstack/react-query';
import {IFileData} from "../../types/documents";

const fetchPosts = async () => {
    const { data } = await axiosInstance.get('/api/offer');
    return data;
};

const DocumentsPage = () => {

    const [formValues, setFormValue] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const { data = [], error, isLoading, refetch } = useQuery<IFileData[]>({
        queryKey: ['posts'],
        queryFn: fetchPosts,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setFormValue(file);
    };

    const handleSave = async () => {
        if (!formValues) {
            toast.error('Пожалуйста, выберите файл');
            return;
        }
        const data = new FormData();
        data.append('offer', formValues);


        setLoading(true);
        try {
            const res = await axiosInstance.post('/api/offer/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setFormValue(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            refetch()
            toast.success('Документ оферты успешно обновлен');
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось обновить документ оферты');
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
              </Spinner>
          </div>
        );
    }

    return (
      <Container className='ms-0'>
          <h3>Документы</h3>

          <div className={'mt-5 mb-2'}>
              <h4 className={'mb-3'}>Договор оферты</h4>
              <input ref={fileInputRef} type='file' className='form-control' onChange={handleChange}/>
          </div>

          <div className={'mb-5'}>
              <h5>Предыдущие файлы:</h5>
              <ListGroup>
                  {data?.map(item => (
                    <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center">
                        <span>{item.filename}</span>
                        <a
                          href={item.filepath}
                          download={item.filename}
                          className="btn btn-primary"
                        >
                            Скачать
                        </a>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
          </div>

          <Button onClick={handleSave}>
              Сохранить
          </Button>
      </Container>
    );
};

export default DocumentsPage;