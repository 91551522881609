import React from 'react'
import { Link } from 'react-router-dom'
import { useStores } from '../../../stores'
import { observer } from 'mobx-react'
import { ROUTES } from '../../../App'
import { GetDictionaryTitle } from '../../../helpers/GetDictionaryTitle'

type Props = {}

const Aside = (props: Props) => {
    const { routerStore } = useStores()

    return (
        <aside>
            <ul className='main-menu'>
                <li>
                    <h4>Пользователи</h4>
                    <ul className='main-menu-links'>
                        <li className={routerStore.currentPath === '/users' ? 'active' : ''}>
                            <Link to="/users">
                                <div className='main-menu-links-item'>
                                    <span><i className="fa-regular fa-user"></i></span>
                                    <p>
                                        Пользователи
                                    </p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <h4>Партнеры</h4>
                    <ul className='main-menu-links'>
                        <li className={routerStore.currentPath === '/partners' ? 'active' : ''}>
                            <Link to="/partners">
                                <div className='main-menu-links-item'>
                                    <span><i className="fa-regular fa-handshake"></i></span>
                                    <p>
                                        Партнеры
                                    </p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <h4>Документы</h4>
                    <ul className='main-menu-links'>
                        <li className={routerStore.currentPath === '/documents' ? 'active' : ''}>
                            <Link to="/documents">
                                <div className='main-menu-links-item'>
                                    <span><i className="fa-solid fa-file"></i></span>
                                    <p>
                                        Документы
                                    </p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <h4>Справочники</h4>
                    <ul className='main-menu-links'>
                        {
                            ROUTES.map((r) => {
                                return <li key={r} className={'/' + r === routerStore.currentPath ? 'active' : ''}>
                                    <Link to={r}>
                                        <div className='main-menu-links-item'>
                                            <span><i className="fa-solid fa-book"></i></span>
                                            <p>
                                                {GetDictionaryTitle(r)}
                                            </p>
                                        </div>
                                    </Link>
                                </li>
                            })
                        }
                    </ul>
                </li>

            </ul>
        </aside>
    )
}

export default observer(Aside)