import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import MobxProvider from './stores';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <MobxProvider>
      <App />
    </MobxProvider>
  </QueryClientProvider>
);